import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

// material-ui
import { Typography } from '@mui/material';

// project-imports
import MenuLogo from './MenuLogo';
import LogoIcon from './LogoIcon';
// import { APP_DEFAULT_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, isIcon, sx }) => <Typography sx={sx}>{isIcon ? <LogoIcon /> : <MenuLogo reverse={reverse} />}</Typography>;

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object
  // to: PropTypes.string
};

export default LogoSection;
