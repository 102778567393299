// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
// import { dispatch } from '../index';

const initialState = {
  error: null,
  user: {}
};

// ==============================|| SLICE - CHAT ||============================== //

const UserManagementDetailsSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET User Details
    getUserDetailsSuccess(state, action) {
      state.user = action.payload;
    }
  }
});

// Reducer

// export function insertChat(chat) {
//   return async () => {
//     try {
//       await axios.post('/api/chat/insert', chat);
//     } catch (error) {
//       dispatch(chat.actions.hasError(error));
//     }
//   };
// }

export const { hasError, getUserDetailsSuccess } = UserManagementDetailsSlice.actions;

export const getUserDetails = (id) => async (dispatch) => {
  const token = localStorage.getItem('serviceToken');

  try {
    const response = await axios.post(
      'user/user-management/edit-user/',
      {
        user_id: id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.data.response_code === 200) {
      dispatch(getUserDetailsSuccess(response.data));
      // console.log('im displaying User Details');
    } else {
      console.log('Something went wrong in API call');
    }
  } catch (error) {
    dispatch(hasError(error));
    console.log('error');
  }
};

export default UserManagementDetailsSlice.reducer;
