// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';

const initialState = {
  error: null,
  visitorDetail: {}
};

// ==============================|| SLICE - VISITOR DETAILS ||============================== //

const visitorDetailsSlice = createSlice({
  name: 'visitorDetail', // This should match the key used in the store
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET VISITOR DETAILS SUCCESS
    getVisitorDetailsSuccess(state, action) {
      state.visitorDetail = action.payload;
    }
  }
});

// Reducer
export const { hasError, getVisitorDetailsSuccess } = visitorDetailsSlice.actions;

export const getVisitor = (id) => async (dispatch) => {
  const token = localStorage.getItem('serviceToken');
  try {
    const response = await axios.post(
      'visit/visit-view/',
      {
        user_id: id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.data.response_code === 200) {
      dispatch(getVisitorDetailsSuccess(response.data));
    } else {
      console.log('Something went wrong in API call');
    }
  } catch (error) {
    dispatch(hasError(error));
    console.log('error', error);
  }
};

export default visitorDetailsSlice.reducer;
