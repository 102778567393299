// import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// project-imports
// import axios from 'utils/axios';
// import dashboard from './dashboard';
// import { Children } from 'react';

// initial state
const initialState = {
  openItem: ['dashboard'],
  openComponent: 'buttons',
  selectedID: null,
  drawerOpen: false,
  componentDrawerOpen: true,
  menu: {},
  error: null
};

// ==============================|| SLICE - MENU ||============================== //

export const Response = {
  dashboard: {
    children: [
      {
        children: [
          {
            breadcrumbs: false,
            id: 'default',
            title: 'default',
            type: 'item',
            url: '/dashboard/default'
          },
          {
            breadcrumbs: false,
            id: 'analytics',
            title: 'analytics',
            type: 'item',
            url: '/dashboard/analytics'
          }
        ],
        icon: 'dashboard',
        id: 'dashboard',
        title: 'dashboard',
        type: 'collapse'
      },
      {
        chip: {
          color: 'primary',
          label: 'new',
          size: 'small',
          variant: 'combined'
        },
        icon: 'components',
        id: 'components',
        target: true,
        title: 'components',
        type: 'item',
        url: '/components-overview/buttons'
      }
    ],
    icon: 'dashboard',
    id: 'group-dashboard',
    title: 'dashboard',
    type: 'group'
  }
};
// const response = await axios.get('api/menu/dashboard');
// console.log(response.data, 'This is menu response');

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    openComponentDrawer(state, action) {
      // console.log(action.payload, 'this is the openComponentDrawer action');
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    hasError(state, action) {
      state.error = action.payload;
    }
  }

  // extraReducers(builder) {
  //   builder.addCase(fetchMenu.fulfilled, (state, action) => {
  //     console.log(action.payload.dashboard, 'this is the slicer');
  //     state.menu = action.payload.dashboard;
  //   });
  // }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, activeID } = menu.actions;
