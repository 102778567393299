import { lazy } from 'react';

// project-imports
import MainLayout from 'layout_vms/mainlayout_vms';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard_vms/AuthGuard';
// import CommonLayout from 'layout_vms/commonlayout_vms';
import RouteGuard from 'utils/route-guard_vms/RouteGuard';

const DashboardHome = Loadable(lazy(() => import('pages_vms/dashboard_vms/home')));
const Visitors = Loadable(lazy(() => import('pages_vms/visitor_vms/Vistors_vms')));
const Projects = Loadable(lazy(() => import('pages_vms/projects_vms/projects_vms')));
const Reports = Loadable(lazy(() => import('pages_vms/reports_vms/reports_vms')));
const UserManagement = Loadable(lazy(() => import('pages_vms/user_management_vms/user_management_vms')));
const Manager = Loadable(lazy(() => import('pages_vms/assign_managers/Manager')));
const Logs = Loadable(lazy(() => import('pages_vms/logs_vms/logs_vms')));
const ViewReports = Loadable(lazy(() => import('pages_vms/reports_vms/reportsView')));
const ViewVisitors = Loadable(lazy(() => import('pages_vms/visitor_vms/ViewVisitors')));
const Viewproject = Loadable(lazy(() => import('pages_vms/projects_vms/Viewproject')));
const ViewUser = Loadable(lazy(() => import('pages_vms/user_management_vms/ViewUser')));
const AddUser = Loadable(lazy(() => import('pages_vms/user_management_vms/AddUser')));
const ViewManager = Loadable(lazy(() => import('pages_vms/assign_managers/ViewManager')));
const GenerateOTP = Loadable(lazy(() => import('pages_vms/generateOTP/generateopt')));
const Settings = Loadable(lazy(() => import('pages_vms/settings/settings')));
const Enterdetails = Loadable(lazy(() => import('pages_vms/generateOTP/Enterdetails')));
const Checkin = Loadable(lazy(() => import('pages_vms/check_in/Check_in')));
const AddManager = Loadable(lazy(() => import('pages_vms/assign_managers/AddManager')));
const ExpectedVisitors = Loadable(lazy(() => import('pages_vms/reports_vms/expectedVisitors')));
const CustomerWaitingList = Loadable(lazy(() => import('pages_vms/sales_manager/CustomerWaitinglist')));
const GREDashboard = Loadable(lazy(() => import('pages_vms/Gre/Dashboard')));

const MainRoutes_VMS = {
  path: 'vms/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'dashboard',
      element: (
        <RouteGuard allowedRoles={['Admin', 'Security']}>
          <DashboardHome />
        </RouteGuard>
      )
    },
    {
      path: 'generateotp',
      element: <GenerateOTP />
    },
    {
      path: 'settings',
      element: <Settings />
    },
    {
      path: 'enterdetails',
      element: <Enterdetails />
    },
    {
      path: 'checkin',
      element: <Checkin />
    },
    {
      path: 'visitors',
      children: [
        {
          path: 'home',
          element: (
            <RouteGuard allowedRoles={['Admin', 'Sales Manager', 'Sales Head', 'Security', 'GRE']}>
              <Visitors />
            </RouteGuard>
          )
        },
        {
          path: 'view',
          element: (
            <RouteGuard allowedRoles={['Admin', 'Sales Manager', 'Sales Head', 'Security', 'GRE']}>
              <ViewVisitors />
            </RouteGuard>
          )
        }
      ]
    },
    {
      path: 'projects',
      children: [
        {
          path: 'home',
          element: (
            <RouteGuard allowedRoles={['Admin', 'Sales Manager', 'Sales Head', 'GRE']}>
              <Projects />
            </RouteGuard>
          )
        },
        {
          path: 'view',
          element: (
            <RouteGuard allowedRoles={['Admin', 'Sales Manager', 'Sales Head', 'GRE']}>
              <Viewproject />
            </RouteGuard>
          )
        }
      ]
    },
    {
      path: 'reports',
      children: [
        {
          path: 'home',
          element: (
            <RouteGuard allowedRoles={['Admin', 'Sales Manager', 'Sales Head', 'Sales Head', 'GRE']}>
              <Reports />
            </RouteGuard>
          )
        },
        {
          path: 'view',
          element: (
            <RouteGuard allowedRoles={['Admin', 'Sales Manager', 'Sales Head', 'GRE']}>
              <ViewReports />
            </RouteGuard>
          )
        },
        {
          path: 'expected',
          element: (
            <RouteGuard allowedRoles={['Admin', 'Sales Manager', 'Sales Head', 'GRE']}>
              <ExpectedVisitors />
            </RouteGuard>
          )
        }
      ]
    },
    {
      path: 'usermanagement',
      children: [
        {
          path: 'home',
          element: (
            <RouteGuard allowedRoles={['Admin']}>
              <UserManagement />
            </RouteGuard>
          )
        },
        {
          path: 'view',
          element: (
            <RouteGuard allowedRoles={['Admin']}>
              <ViewUser />
            </RouteGuard>
          )
        },
        {
          path: 'adduser',
          element: (
            <RouteGuard allowedRoles={['Admin']}>
              <AddUser />
            </RouteGuard>
          )
        }
      ]
    },
    {
      path: 'salesManager',
      children: [
        {
          path: 'home',
          element: (
            <RouteGuard allowedRoles={['Sales Manager']}>
              <CustomerWaitingList />
            </RouteGuard>
          )
        }
      ]
    },
    {
      path: 'gre',
      children: [
        {
          path: 'home',
          element: (
            <RouteGuard allowedRoles={['GRE']}>
              <GREDashboard />
            </RouteGuard>
          )
        }
      ]
    },
    {
      path: 'salesHead',
      children: [
        {
          path: 'home',
          element: (
            <RouteGuard allowedRoles={['Sales Head']}>
              <Manager />
            </RouteGuard>
          )
        },
        {
          path: 'view',
          element: (
            <RouteGuard allowedRoles={['Sales Head']}>
              <ViewManager />
            </RouteGuard>
          )
        },
        {
          path: 'addmanager',
          element: (
            <RouteGuard allowedRoles={['Sales Head']}>
              <AddManager />
            </RouteGuard>
          )
        }
      ]
    },
    {
      path: 'logs',
      element: (
        <RouteGuard allowedRoles={['Admin', 'Sales Manager', 'Sales Head', 'GRE']}>
          <Logs />
        </RouteGuard>
      )
    }
  ]
};

export default MainRoutes_VMS;
