// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
  error: null,
  project: {},
  updateDetails: {}, // Ensure this is not nested
  addProject: {}
};

// ==============================|| SLICE - CHAT ||============================== //

const projectDetailsSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET Project Details
    getProjectDetailsSuccess(state, action) {
      state.project = action.payload;
    },

    //Update Project Details
    updateProjectSuccess(state, action) {
      state.updateDetails = action.payload; // Ensure this is directly assigned to state
    },

    //Add new Project
    addProjectSuccess(state, action) {
      state.addProject = action.payload; // Ensure this is directly assigned to state
    }
  }
});

// Reducer

// export function insertChat(chat) {
//   return async () => {
//     try {
//       await axios.post('/api/chat/insert', chat);
//     } catch (error) {
//       dispatch(chat.actions.hasError(error));
//     }
//   };
// }

export const { hasError, getProjectDetailsSuccess, updateProjectSuccess, addProjectSuccess } = projectDetailsSlice.actions;

export const getProjectDetails = (id) => async (dispatch) => {
  const token = localStorage.getItem('serviceToken');

  try {
    const response = await axios.post(
      'projects/view/',
      {
        project_id: id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.data.response_code === 200) {
      dispatch(getProjectDetailsSuccess(response.data));
    } else {
      console.log('Something went wrong in API call');
    }
  } catch (error) {
    dispatch(hasError(error));
    console.log('error');
  }
};

export const ProjectUpdateDetails = (updateDetails) => async () => {
  const token = localStorage.getItem('serviceToken');
  try {
    const response = await axios.put(
      'projects/edit/',
      {
        project_id: updateDetails.project_id,
        name: updateDetails.name,
        location: updateDetails.location
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.data.response_code === 200) {
      dispatch(updateProjectSuccess(response.data)); // Ensure this is correctly dispatched
      // console.log('im Updating Project Details');
    } else {
      console.log('Something went wrong in API call');
    }
  } catch (error) {
    dispatch(hasError(error));
    console.log('redux error', error);
  }
};

export const addProject = (addProject) => async () => {
  const token = localStorage.getItem('serviceToken');
  try {
    const response = await axios.post(
      'projects/add/',
      {
        name: addProject.name,
        location: addProject.location
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.data.response_code === 201) {
      dispatch(addProjectSuccess(response.data)); // Ensure this is correctly dispatched
      // console.log('im Adding new Project');
      dispatch(
        openSnackbar({
          open: true,
          message: 'Project Added Successfully',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Adding Project Failed',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    }
  } catch (error) {
    dispatch(hasError(error));
    console.log('redux error', error);
  }
};
export default projectDetailsSlice.reducer;
