// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
// import { dispatch } from '../index';

const initialState = {
  error: null,
  user: {}
};

// ==============================|| SLICE - CHAT ||============================== //

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.user = action.payload;
    }
  }
});

// Reducer
export const { hasError, getUserSuccess } = userSlice.actions;

// export function insertChat(chat) {
//   return async () => {
//     try {
//       await axios.post('/api/chat/insert', chat);
//     } catch (error) {
//       dispatch(chat.actions.hasError(error));
//     }
//   };
// }

export const getUser = (id) => async (dispatch) => {
  const token = localStorage.getItem('serviceToken');
  try {
    const response = await axios.post(
      'user/user-management/edit-user/',
      {
        user_id: id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    const Data = response?.data?.data;
    dispatch(getUserSuccess(Data));
  } catch (error) {
    dispatch(hasError(error));
    console.log('error', error);
  }
};

export default userSlice.reducer;
