// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project-imports
// import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout_vms/mainlayout_vms/Drawer/DrawerHeader';

import { MenuOrientation } from 'config';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();
  const Role = localStorage.getItem('role');

  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      {Role !== 'Security' && <Notification />}

      <Profile />
    </>
  );
};

export default HeaderContent;
