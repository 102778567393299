import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

// project-imports
import NavGroup from './NavGroup';
import { House2, People, Buliding, Stickynote, Personalcard, DocumentFilter, CalendarTick } from 'iconsax-react';

import { useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { MenuOrientation } from 'config';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const icons = {
  home: House2,
  visitors: People,
  projects: Buliding,
  reports: Stickynote,
  Managers: Personalcard,
  UserManagement: DocumentFilter,
  Logs: CalendarTick
};

const allMenuItems = [
  {
    id: 'Dashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/vms/dashboard/',
    icon: icons.home,
    role: ['Admin', 'Security'] // Example roles
  },
  {
    id: 'SalesManagerDashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/vms/salesManager/home',
    icon: icons.Managers,
    role: ['Sales Manager']
  },
  {
    id: 'SalesHeadDashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/vms/salesHead/home',
    icon: icons.Managers,
    role: ['Sales Head']
  },
  {
    id: 'GreDashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/vms/gre/home',
    icon: icons.Managers,
    role: ['GRE']
  },
  {
    id: 'Visitors',
    title: 'Visitors',
    type: 'item',
    url: '/vms/visitors/home',
    icon: icons.visitors,
    role: ['Admin', 'Security', 'Sales Manager', 'Sales Head', 'GRE']
  },
  {
    id: 'Projects',
    title: 'Projects',
    type: 'item',
    url: '/vms/projects/home',
    icon: icons.projects,
    role: ['Admin', 'Sales Manager', 'Sales Head', 'GRE']
  },
  {
    id: 'Reports',
    title: 'Reports',
    type: 'collapse', // Change type to 'collapse' to indicate submenu
    icon: icons.reports,
    role: ['Admin', 'Sales Manager', 'Sales Head'],
    children: [
      {
        id: 'Project',
        title: 'Project',
        type: 'item',
        url: '/vms/reports/home',
        role: ['Admin', 'Sales Manager', 'Sales Head']
      },
      {
        id: 'ExpectedCustomers',
        title: "Tomorrow's Anticipated ",
        type: 'item',
        url: '/vms/reports/expected',
        role: ['Admin', 'Sales Manager', 'Sales Head']
      }
    ]
  },
  {
    id: 'user-management',
    title: 'User Management',
    type: 'item',
    url: '/vms/usermanagement/home',
    icon: icons.UserManagement,
    role: ['Admin']
  }
  // {
  //   id: 'logs',
  //   title: 'Logs',
  //   type: 'item',
  //   url: '/vms/logs',
  //   icon: icons.Logs,
  //   role: ['Admin', 'Sales Manager', 'Sales Head']
  // }
];

const Navigation = () => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const [selectedItems, setSelectedItems] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [menuItems, setMenuItems] = useState({ items: [] });

  useEffect(() => {
    const userRole = localStorage.getItem('role');
    if (userRole) {
      const filteredItems = allMenuItems.filter(
        (item) => item.role.includes(userRole) || (item.children && item.children.some((child) => child.role.includes(userRole)))
      );
      const vms = {
        id: 'group-vms',
        icon: icons.widgets,
        type: 'group',
        children: filteredItems
      };
      setMenuItems({ items: [vms] });
    }
  }, []);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const renderMenuItems = (items) => {
    return items.map((item) => {
      if (item.type === 'group') {
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      } else if (item.type === 'collapse') {
        return (
          <Box key={item.id} sx={{ ml: 2 }}>
            <Typography variant="h6" color="inherit">
              {item.title}
            </Typography>
            <Box>{renderMenuItems(item.children)}</Box>
          </Box>
        );
      } else {
        return (
          <Typography key={item.id} variant="h6" color="inherit">
            {item.title}
          </Typography>
        );
      }
    });
  };

  const navGroups = renderMenuItems(menuItems.items.slice(0, lastItemIndex + 1));

  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex', xl: 'flex' } : 'block'
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
