import { Button, Stack, Grid, Typography } from '@mui/material';
import AnimateButton from 'components/@extended/AnimateButton';
import { useNavigate, useLocation } from 'react-router-dom';

// ==============================|| DRAWER CONTENT - NAV CARD ||============================== //

const NavCard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (path, state = {}) => {
    // If already on the same path, force a full reload
    if (location.pathname === path) {
      window.location.href = path;
    } else {
      navigate(path, { state });
    }
  };

  return (
    <Grid container spacing={2} sx={{ p: 3, maxWidth: '100vw', overflow: 'hidden' }}>
      <Grid item xs={12}>
        <Stack alignItems="center" spacing={2}>
          <AnimateButton fullWidth>
            <Button
              variant="shadow"
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                handleNavigate('/vms/checkin', { detail: true }); // Navigate with state
              }}
              target="_blank"
              style={{ width: '120px' }} // Ensure uniform size
            >
              <Typography style={{ fontSize: 'clamp(10px, 1.5vw, 14px)', flex: '0 0 120px' }}>Check-In</Typography>
            </Button>
          </AnimateButton>
          <AnimateButton fullWidth>
            <Button
              variant="shadow"
              fullWidth
              onClick={() => {
                handleNavigate('/vms/generateotp');
              }}
              target="_blank"
              style={{ width: '120px' }} // Ensure uniform size
            >
              <Typography style={{ fontSize: 'clamp(10px, 1.5vw, 14px)', flex: '0 0 120px' }}>Generate OTP</Typography>
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default NavCard;
