import PropTypes from 'prop-types';
import { createContext, useReducer, useEffect, useState } from 'react';

// third-party
// import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import { openSnackbar } from 'store/reducers/snackbar';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';

// const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user_id: '',
  project: '',
  user: {}
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken, role, roleId, username, refreshtoken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('role', role);
    localStorage.setItem('roleId', roleId);
    localStorage.setItem('username', username);
    localStorage.setItem('refreshtoken', refreshtoken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const token = localStorage.getItem('token');

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const [user, setUser] = useState({});
  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        const role = localStorage.getItem('role'); // Fetch role from local storage
        const roleId = localStorage.getItem('roleId');
        const username = localStorage.getItem('username');
        const refreshtoken = localStorage.getItem('refreshtoken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken, role, roleId, username, refreshtoken);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user_id: roleId,
              user: user
            }
          });
        } else {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };
    init();
  }, [user]);

  const generateOTP = async (values) => {
    try {
      const response = await axios.post(
        'user/generate-otp/',
        {
          phone_no: values.Phone_no
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  };

  const login = async (number, values) => {
    const phone_no = number;
    const OTP = JSON.parse(values);
    try {
      const response = await axios.post(
        'user/validate-otp/',
        {
          phone_no: phone_no,
          otp: OTP
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.data.response_code === 200) {
        const { data } = response;
        setUser(data);
        const serviceToken = data.access_token;
        const role = data.role;
        const roleId = data.user_id;
        const username = data.name;
        const refreshtoken = data.refresh_token;
        setSession(serviceToken, role, roleId, username, refreshtoken);
        verifyToken(serviceToken);
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user_id: data.user_id,
            user: data
          }
        });
        Dispatch(
          openSnackbar({
            open: true,
            message: 'OTP verified successfully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        switch (response.data.role) {
          case 'Sales Manager':
            navigate('/vms/salesManager/home');
            break;
          case 'Sales Head':
            navigate('/vms/salesHead/home');
            break;
          case 'GRE':
            navigate('/vms/gre/home');
            break;
          default:
            navigate('/vms/dashboard/');
            break;
        }
        return response;
      }
      if (response.data.response_code === 401) {
        Dispatch(
          openSnackbar({
            open: true,
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
        return response;
      }
      if (response.data.response_code === 404) {
        Dispatch(
          openSnackbar({
            open: true,
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
        return response;
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: false
        }
      });
      Dispatch(
        openSnackbar({
          open: true,
          message: 'Internal server error',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return error;
    }
  };

  const logout = async () => {
    const serviceToken = localStorage.getItem('serviceToken');
    const refreshtoken = localStorage.getItem('refreshtoken');
    try {
      const response = await axios.post(
        'logout/',
        {
          refresh_token: refreshtoken
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${serviceToken}`
          }
        }
      );
      if (response.data.response_code === 200) {
        navigate('/vms/login');
        setSession(null);
        localStorage.removeItem('role');
        localStorage.removeItem('roleId');
        localStorage.removeItem('username');
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('refreshtoken');
        dispatch({ type: LOGOUT });
        Dispatch(
          openSnackbar({
            open: true,
            message: 'Logout Success',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
      }
      if (response.data.response_code !== 200) {
        const errorMessage = response.data.message;
        Dispatch(
          openSnackbar({
            open: true,
            message: errorMessage,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }
    } catch (error) {
      console.log(error);
      Dispatch(
        openSnackbar({
          open: true,
          message: 'Internal server error',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return error;
    }
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, generateOTP, user }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
