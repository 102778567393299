// material-ui
import { Button, Stack, Grid, Typography } from '@mui/material';
// import { Profile2User } from 'iconsax-react';
import { ReactComponent as Checkin } from 'assets/images/SVG/Check-in Dark.svg';
import { ReactComponent as GenerateOTP } from 'assets/images/SVG/Generate OTP Dark.svg';

// assets
import { useNavigate } from 'react-router-dom';

// ==============================|| DRAWER CONTENT - NAV CARD ||============================== //

const NavCardIcon = () => {
  const navigate = useNavigate();

  return (
    <Grid container spacing={2} sx={{ p: 3, maxWidth: '100vw', overflow: 'hidden' }}>
      <Grid item xs={12}>
        <Stack alignItems="center" spacing={2}>
          <Button
            fullWidth
            onClick={(e) => {
              e.stopPropagation();
              navigate('/vms/checkin', { state: { detail: true } }); // Navigate with state
            }}
            target="_blank"
          >
            <Typography>
              <Checkin />
            </Typography>
          </Button>
          <Button
            fullWidth
            onClick={(e) => {
              e.stopPropagation();
              navigate('/vms/generateotp');
            }}
            target="_blank"
          >
            <Typography>
              <GenerateOTP />
            </Typography>
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default NavCardIcon;
