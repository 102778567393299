import axios from 'axios';

// VMS URL FOR OFFICE WIFI TESTING ONLY
// const axiosServices = axios.create({ baseURL: 'http://192.168.10.118:8000/' });

// VMS URL FOR CUSTOM TESTING ONLY
// const axiosServices = axios.create({ baseURL: 'http://172.20.10.2:8000/' });

// VMS URL FOR OUR SERVER
const axiosServices = axios.create({ baseURL: 'https://vmsdev.sumadhuradev.com/' });

// URL FOR GETTING MOCK DATA
// const axiosServices = axios.create({ baseURL: 'https://mock-data-api-nextjs.vercel.app/' });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

// axiosServices.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log(error);
//     // if (error.response.status === 401 && !window.location.href.includes('/login')) {
//     //   window.location.pathname = '/login';
//     // }
//     return Promise.reject((error.response && error.response.data) || 'Wrong Services');
//   }
// );

export default axiosServices;
