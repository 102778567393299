import { Navigate } from 'react-router-dom';

const RoleGuard = ({ allowedRoles, children }) => {
  const user = localStorage.getItem('role');

  if (!user || !allowedRoles.includes(user)) {
    return <Navigate to="/vms/unauthorized" />; // Redirect to an unauthorized page or any fallback route
  }
  return children; // Render the child component if access is allowed
};

export default RoleGuard;
