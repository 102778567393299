import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project-imports
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
// import ComponentsRoutes from './ComponentsRoutes';
import LoginRoutes_VMS from './LoginRoutes_vms';
// import LoginRoutes from './LoginRoutes';
// import MainRoutes from './MainRoutes';
import MainRoutes_VMS from './MainRoutes_VMS';

// render - landing page
// const PagesLanding = Loadable(lazy(() => import('pages/landing')));
// const DashboardHome = Loadable(lazy(() => import('pages_vms/dashboard_vms/home')));
const AuthLogin = Loadable(lazy(() => import('pages_vms/auth_vms/login')));

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout />,
      children: [
        {
          path: '/',
          element: <AuthLogin />
        }
      ]
    },
    // LoginRoutes,
    LoginRoutes_VMS,
    // ComponentsRoutes,
    // MainRoutes,
    MainRoutes_VMS
  ]);
}
