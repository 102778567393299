import { lazy } from 'react';

// project-imports
// import GuestGuard from 'utils/route-guard/GuestGuard';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages_vms/auth_vms/login')));
const VisitorType = Loadable(lazy(() => import('pages_vms/SubmissionForms/VisitorType')));
const ThankYou = Loadable(lazy(() => import('pages_vms/SubmissionForms/Thankyou')));
const AuthCodeVerification = Loadable(lazy(() => import('pages_vms/auth_vms/code-verification')));
const MainForm = Loadable(lazy(() => import('pages_vms/SubmissionForms/DirectVisitor/MainForm')));
const Unauthorized = Loadable(lazy(() => import('pages_vms/Unauthorized')));

// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes_VMS = {
  path: '/vms/',
  children: [
    {
      path: 'login/',
      element: <AuthLogin />
    },
    {
      path: 'verification/',
      element: <AuthCodeVerification />
    },
    {
      path: 's/:encodedString',
      element: <MainForm />
    },
    {
      path: 'response/',
      element: <ThankYou />
    },
    {
      path: 'testing/',
      element: <VisitorType />
    },
    {
      path: 'unauthorized/',
      element: <Unauthorized />
    }
  ]
};

export default LoginRoutes_VMS;
