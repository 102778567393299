// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
// import { dispatch } from '../index';

// ==============================|| SLICE - CHAT ||============================== //
const initialState = {
  user: {}, // Initialize user as an empty object
  loading: false,
  error: null
};

// ==============================|| SLICE - CHAT ||============================== //

const salesManagerUserSlice = createSlice({
  name: 'salesManagerUser',
  initialState,
  reducers: {
    requestStart(state) {
      state.loading = true;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.user = action.payload;
      state.loading = false;
    }
  }
});

// Reducer
export const { hasError, getUserSuccess, requestStart } = salesManagerUserSlice.actions;

export const getUserSalesManager = (id) => async (dispatch) => {
  const token = localStorage.getItem('serviceToken');
  try {
    const response = await axios.post(
      'sales-manager/visitor-details/',
      {
        member_id: id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    const Data = response?.data?.data;
    dispatch(getUserSuccess(Data));
  } catch (error) {
    dispatch(hasError(error));
    console.log('error', error);
  }
};

export default salesManagerUserSlice.reducer;
